<template>
    <div>
        <b-container>
            <b-row>
                <b-col>
                    <div>
                        <strong>Register Instructors for {{session.label}}</strong>
                        <br /><br />
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group
                            label-for="ubc-id-search"
                            label="UBC ID:">
                        <b-input-group>
                            <b-input id="ubc-id-search" size="sm"
                                     v-model="ubcIdFilter" />
                            <b-input-group-addon>
                                <b-button size="sm"
                                          @click="ubcIdFilter = ''">
                                    <font-awesome-icon icon="times" />
                                </b-button>
                            </b-input-group-addon>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                            label-for="name-search"
                            label="Name:">
                        <b-input-group>
                            <b-input id="name-search" size="sm"
                                     v-model="nameFilter" />
                            <b-input-group-addon>
                                <b-button size="sm"
                                          @click="nameFilter = ''">
                                    <font-awesome-icon icon="times" />
                                </b-button>
                            </b-input-group-addon>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div>
                        <b-table small striped hover bordered stacked="sm" head-variant="dark"
                                 :items="registrableInstructors"
                                 :fields="fields" >
                            <template v-slot:cell(ubcId)="row">{{row.item.user.ubcId}}</template>
                            <template v-slot:cell(fullname)="row">{{row.item.user.fullname}}</template>
                            <template v-slot:cell(register)="row">
                                <b-dropdown
                                        :variant="statusVariant(row.item.status)"
                                        :text=row.item.status
                                        size="sm">
                                    <b-dropdown-item
                                            href="#" class="options"
                                            @click="register(row.item)">Register</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                        <template v-if="!registrableInstructors">
                            No registrable instructors found for this session
                        </template>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <!-- CONFIRM REGISTRATION MODAL -->
        <b-modal :id="registrationModalId"
                 size="xl"
                 scrollable
                 noCloseOnBackdrop
                 noCloseOnEsc
                 hideHeaderClose
                 hideFooter
                 title="Confirm Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <ConfirmRegistration
                    :registrationRequest="registrationRequest"
                    :requestType="requestType"
                    @registered="registered"
                    @canceled="canceled" />
        </b-modal>
    </div>
</template>

<script>
    import { Prop, Component, Vue} from 'vue-property-decorator';
    import {sprintf} from 'sprintf-js';
    import _ from 'underscore';
    import store from '@/store/store';
    import {InstructorSession, NULL_INSTRUCTOR_SESSION} from "@/model/session";
    import {Status, RegistrationRequestType} from "@/model/registration";
    import ConfirmRegistration from "@/views/private/tracks/ConfirmRegistration.vue";
    import {errorToastOptions} from "../../../util/formatters";

    @Component({
        components: {
            ConfirmRegistration
        },
        asyncComputed: {
            registrableInstructors: {
                async get() {
                    try {
                        await store.dispatch('instructor/loadInstructors');
                    }
                    catch (error) {
                        this.$bvToast.toast(error.message, errorToastOptions);
                    }
                    const instructors = store.getters['instructor/registrableInstructors'](this.session.workshopInstanceId);
                    const filteredInstructors = _.chain(instructors)
                        .filter(instructor => {
                            if (!_.isEmpty(this.nameFilter)) {
                                return instructor.user.fullname.toUpperCase().includes(this.nameFilter.toUpperCase());
                            }
                            else {
                                return instructor;
                            }
                        })
                        .filter(instructor => {
                            if (!_.isEmpty(this.ubcIdFilter)) {
                                return !_.isEmpty(instructor.user.ubcId) &&
                                    instructor.user.ubcId.toUpperCase().includes(this.ubcIdFilter.toUpperCase());
                            }
                            else {
                                return instructor;
                            }
                        })
                        .value();
                    return filteredInstructors;
                }
            }
        },
        methods: {
            refreshInstructors() {
                this.$asyncComputed.registrableInstructors.update();
            }
        }
    })

    export default class InstructorRegistration extends Vue {
        @Prop({type: InstructorSession, default: NULL_INSTRUCTOR_SESSION}) session;
        ubcIdFilter = '';
        nameFilter = '';
        registrationRequest = null;

        get requestType() {
            return RegistrationRequestType.INSTRUCTOR_SESSION_REGISTRATION;
        }

        get registrationModalId() {
            return sprintf('confirm-instructor-registration-modal-%d', this.session.workshopInstanceId);
        }

        register(request) {
            this.registrationRequest = request;
            this.$bvModal.show(this.registrationModalId);
            this.refreshInstructors();
        }

        registered() {
            this.$bvModal.hide(this.registrationModalId);
            this.registrationRequest = null;
            this.refreshInstructors();
        }

        canceled() {
            this.$bvModal.hide(this.registrationModalId);
            this.registrationRequest = null;
        }

        mounted() {
            // console.log(this.session);
        }

        statusVariant(status) {
            return Status.variant(status);
        }

        get fields() {
            const baseFields = [{
                key: 'ubcId',
                label: 'UBC ID',
                sortable: true
            }, {
                key: 'fullname',
                label: 'Name',
                sortable: true
            }, {
                key: 'register',
                label: 'Status',
                sortable: false
            }];
            return baseFields;
        }
    }
</script>

<style scoped>
    b-dropdown-item.options {
    }
</style>

