<template>
    <div>
        <b-row>
            <b-col class="col-md-12">
                <span v-if="session.workspace.hasErrors" class="review-required">REVIEW REQUIRED<br /></span>
                <br />
                <b-tabs content-class="mt-3"
                        v-model="tabIndex"
                        @activate-tab="changeTab">
                    <b-tab title="Details">
                        <b-row>
                            <b-col cols="6">
                                <b-form-group
                                        label="Start Date → End Date"
                                        :state="errorState('dates')"
                                        invalid-feedback="Required">
                                    <program-date-range
                                            v-model="session.workspace.programDates"
                                            :state="errorState('dates')"
                                            :disabled="!!session.workspace.registrations" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group
                                        label="Length (days)">
                                    <b-input size="sm"
                                             v-model="session.workspace.programDays"
                                             disabled />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-form-group
                                        label="Min Seats"
                                        :state="errorState('minSeats')"
                                        invalid-feedback="Required">
                                    <b-input type="number" size="sm"
                                             v-model="session.workspace.programMinSeats"
                                             :state="errorState('minSeats')" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group
                                        label="Max Seats"
                                        :state="errorState('maxSeats')"
                                        invalid-feedback="Required">
                                    <b-input type="number" size="sm"
                                             v-model="session.workspace.programMaxSeats"
                                             :state="errorState('maxSeats')" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-form-group
                                        label="Instructors Required"
                                        :state="errorState('instructorsNeeded')"
                                        invalid-feedback="Required">
                                    <b-input type="number" size="sm"
                                             v-model="session.workspace.programInstructorsNeeded"
                                             :state="errorState('instructorsNeeded')" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group
                                        label="Open Seats">
                                    <b-input size="sm"
                                             v-model="session.workspace.availableSeats"
                                             disabled />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-form-group
                                        label="Allow Registrations">
                                    <b-form-radio-group
                                            :options="booleanOptions"
                                            v-model="session.workspace.programAllowRegistrations" />
                                </b-form-group>
                                <b-form-group
                                        label="Admin Registrations Only">
                                    <b-form-radio-group
                                            :options="booleanOptions"
                                            v-model="session.workspace.programAdminRegistration" />
                                </b-form-group>
                                <b-form-group
                                        label="Auto Registration Closure">
                                    <b-form-radio-group
                                            :options="booleanOptions"
                                            v-model="session.workspace.programAutoClose" />
                                </b-form-group>
                                <b-form-group
                                        label="COVID-19 Registration Restriction">
                                    <b-form-radio-group
                                            :options="booleanOptions"
                                            v-model="session.workspace.programRequireVaccine" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group
                                        label="Instructor / Developer Comments">
                                    <b-textarea
                                            size="sm" rows="7" max-rows="7"
                                            v-model="session.workspace.programComment" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Setup">
                        <b-row>
                            <b-col cols="12">
                                <span v-if="session.canceled" class="warning">WARNING: This session has been canceled.<br /><br /></span>
                                <span v-if="!session.registrationsAccepted" class="note">Registrations are not currently accepted.<br /><br /></span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                        label="Start Time First Day → End Time Last Day">
                                    <program-time-range
                                            v-model="session.workspace.programTimes" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                        label="Equipment/Props">
                                    <b-textarea
                                            v-model="session.workspace.programEquipment" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                        label="Classroom/Shop Space">
                                    <b-textarea
                                            v-model="session.workspace.programClassroom" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                        label="Room Setup">
                                    <b-textarea
                                            v-model="session.workspace.programRoomSetup" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                        label="Audio/Visual">
                                    <b-textarea
                                            v-model="session.workspace.programAudioVisual" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                        label="Food/Beverage">
                                    <b-textarea
                                            v-model="session.workspace.programFoodBeverage" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                        label="Additional Setup">
                                    <b-textarea
                                            v-model="session.workspace.programAdditionalSetup" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                        label="Need Binders">
                                    <b-form-radio-group :options="booleanOptions"
                                                        v-model="session.workspace.programBinders" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Allocations"
                           v-if="allowAllocations && !session.workspace.isNew"
                           lazy>
                        <Allocations :workshop-instance="session" />
                    </b-tab>
                </b-tabs>
                <div align="right" v-if="showButtons && !hideButtons">
                    <template>
                        <b-button
                                variant="primary" size="sm"
                                @click="$emit('edit', 'cancelEdit', session)">Cancel Edit</b-button>
                    </template>
                    <template v-if="!session.disableDelete">
                        <b-button
                                :disabled="session.disableDelete"
                                variant="danger" size="sm"
                                @click="$emit('edit', 'deleteSession', session)">Delete Session</b-button>
                    </template>
                    <template v-if="!session.disableCancel">
                        <b-button
                                :disabled="session.disableCancel"
                                variant="warning" size="sm"
                                @click="$emit('edit', 'cancelSession', session)">Cancel Session</b-button>
                    </template>
                    <template>
                        <b-button
                                :disabled="session.disableSave"
                                variant="success" size="sm"
                                @click="$emit('edit', 'saveSession', session)">Save Session</b-button>
                    </template>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { Prop, Component, Vue } from 'vue-property-decorator';
    import ProgramTimeRange from '@/views/private/tracks/ProgramTimeRange.vue';
    import ProgramDateRange from '@/views/private/tracks/ProgramDateRange.vue';
    import {InstructorSession, NULL_INSTRUCTOR_SESSION} from "@/model/session";
    import Allocations from "../tracks/Allocations";

    @Component({
        components: {
            Allocations,
            ProgramDateRange,
            ProgramTimeRange
        },
        asyncComputed: {}
    })
    
    export default class InstructorSessionForm extends Vue {
        @Prop({type: Boolean, default: true}) showButtons;
        @Prop({type: InstructorSession, default: NULL_INSTRUCTOR_SESSION}) session;
        tabIndex = 0;
        hideButtons = false;
        allowAllocations = true;

        changeTab(newTabIndex) {
            //Hide buttons for allocations tab only
            this.hideButtons = newTabIndex === 2;
        }

        errorState(field) {
            return !this.session.workspace.sessionErrors[field];
        }

        get booleanOptions() {
            return [{
                value: true,
                text: 'Yes'
            }, {
                value: false,
                text: 'No'
            }];
        }
    }
</script>

<style scoped>
    span.warning {
        color: red;
        font-weight: bold;
    }
    span.note {
        color: royalblue;
    }
    span.review-required {
        color: orangered;
        font-variant: all-small-caps;
    }
</style>