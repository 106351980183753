<template>
    <div class="home-content">
        <b-card-group deck>
            <b-card no-body>
                <template slot="header">
                    <b-row class="clearfix">
                        <!-- YEAR PICKER -->
                        <b-col class="col-md-2">
                            <PickerOfDates type="year" format="yyyy" :clearable="false" v-model="selectedYear">
                                <template v-slot:prepend>
                                    <b-input-group-prepend>
                                        <b-button size="sm" variant="info" @click="prevYear">
                                            <font-awesome-icon icon="arrow-left"/>
                                        </b-button>
                                    </b-input-group-prepend>
                                </template>
                                <template v-slot:append>
                                    <b-input-group-append>
                                        <b-button size="sm" variant="info" @click="nextYear">
                                            <font-awesome-icon icon="arrow-right" />
                                        </b-button>
                                    </b-input-group-append>
                                </template>
                            </PickerOfDates>
                        </b-col>
                        <!-- ADD NEW -->
                        <b-col class="float-right">
                            <template v-if="allowSessionChanges">
                                <b-button
                                        size="sm" variant="dark"
                                        class="float-right"
                                        @click="showNewSession">Add New</b-button>
                            </template>
                        </b-col>
                    </b-row>
                </template>
                <!-- SESSION LIST -->
                <b-list-group flush>
                    <b-list-group-item
                            v-for="session in instructorSessions"
                            :key="session.workshopInstanceId">
                        <div>
                            <span class="session-heading">
                                <b-link
                                        @click="getDetails(session)">{{session.label}} </b-link>
                            </span>
                            <span v-if="allowRegistrationChanges(session)"> {{session.availableSeatsLabel}} </span>
                            <span v-if="allowSessionChanges && isEditable(session)">
                                <b-link v-b-toggle="'instructor-session-'+session.workshopInstanceId">edit</b-link>
                                 |
                            </span>
                            <span v-if="isRegistrable(session)">
                                <b-link @click="showRegistration(session)">register</b-link>
                                 |
                            </span>
                            <span v-if="isEditable(session)">
                                <b-link @click="showRoster(session)">roster</b-link>
                            </span>
                            <span v-if="!allowRegistrationChanges(session)"> *Registrations are not currently accepted</span>
                        </div>
                        <b-collapse
                                :id="'instructor-session-'+session.workshopInstanceId"
                                accordion="instructorSessions">
                            <InstructorSessionForm
                                    :showButtoms="true"
                                    :session="session"
                                    @edit="edit" />
                        </b-collapse>
                    </b-list-group-item>
                </b-list-group>
            </b-card>
        </b-card-group>
        <!--NEW SESSION MODAL-->
        <b-modal :id="newSessionModalId"
                 size="xl"
                 scrollable
                 title="Add New Instructor Session"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <InstructorSessionForm
                    :session="newSession"
                    :showButtons="false" />
            <template v-slot:modal-footer="{ hide }">
                <!--New session does not include options to cancel/delete track or show roster/register for session-->
                <b-button
                        variant="primary"
                        @click="cancelNewSession">Cancel</b-button>
                <b-button
                        :disabled="newSession.disableSave"
                        variant="success"
                        @click="saveNewSession">Save</b-button>
            </template>
        </b-modal>
        <!-- SESSION DETAILS MODAL -->
        <b-modal :id="sessionDetailsModalId"
                 size="xl"
                 scrollable
                 ok-only
                 title="Session Details"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <SessionDetails
                    :session="session" />
        </b-modal>
        <!-- ROSTER MODAL -->
        <b-modal :id="rosterModalId"
                 size="xl" class="p-0"
                 scrollable
                 title="Instructor Roster"
                 header-bg-variant="dark"
                 header-text-variant="white"
                 footer-bg-variant="dark"
                 footer-border-variant="white">
            <instructor-roster :allowRegistrationChanges="allowRegistrationChanges(this.currentSession)"
                               :session="this.currentSession"/>
            <template #modal-footer="{hide}">
                <b-button size="sm" variant="primary" @click="hide">Close</b-button>
            </template>
        </b-modal>
        <!-- REGISTRATION MODAL -->
        <b-modal :id="registrationModalId"
                 size="xl"
                 scrollable
                 ok-only
                 ok-variant="primary"
                 title="Instructor Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <InstructorRegistration
                    :session="this.currentSession" />
        </b-modal>
    </div>
</template>

<script>

import {Component, Vue} from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import store from '@/store/store';
import _ from 'underscore';
import {subYears, addYears} from 'date-fns';
import InstructorSessionForm from '@/views/private/instructor/InstructorSessionForm.vue';
import DatePicker from 'vue2-datepicker';
import {sprintf} from "sprintf-js";
import {NULL_INSTRUCTOR_SESSION} from "@/model/session";
import InstructorRoster from '@/views/private/instructor/InstructorRoster.vue';
import InstructorRegistration from '@/views/private/instructor/InstructorRegistration.vue';
import SchedulePage from "../schedule/SchedulePage";
import SessionDetails from '@/views/private/tracks/SessionDetails.vue';
import PickerOfDates from '@/components/shared/PickerOfDates';
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

@Component({
    components: {
        SchedulePage,
        DatePicker,
        PickerOfDates,
        InstructorSessionForm,
        InstructorRoster,
        InstructorRegistration,
        SessionDetails
    },
    asyncComputed: {
        instructorSessions: {
            async get() {
                try {
                    await store.dispatch('instructor/loadInstructorSessions');
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                }
                const sessions = store.getters['instructor/instructorSessions'];
                const filteredSessions = _.chain(sessions)
                    //Only show non-cancelled sessions (per Rocio 6/17, incl. cancelled)
                    // .filter(session => {
                    //     return !session.canceled;
                    // })
                    //Only show sessions in selected year
                    .filter(session => {
                        return session.inYear(this.selectedYear.getFullYear());
                    })
                    //Chronological order
                    .sortBy(session => {
                        return session.dates[0];
                    })
                    .value();
                return filteredSessions;
            },
            default: [],
            watch: ['selectedYear']
        },
        roster: {
            async get() {
                return [];
            }
        }
    },
    methods: {
        refreshSessions() {
            this.$asyncComputed.instructorSessions.update();
        }
    }
})

export default class InstructorSessions extends Vue {
    selectedYear = new Date();
    newSession = NULL_INSTRUCTOR_SESSION;
    currentSession = NULL_INSTRUCTOR_SESSION;
    session = null;

    get sessionDetailsModalId() {
        if (_.isNull(this.session)) {
            return 'session-details-modal-invalid';
        }
        else {
            return sprintf('session-details-modal-%d', this.session.sessionId);
        }
    }

    getDetails(session) {
        this.session = session;
        this.$bvModal.show(this.sessionDetailsModalId);
    }

    async beforeRouteEnter(to, from, next) {
        store.commit('setBreadcrumbs', [
            Breadcrumb.create('Administration', {name: 'adminMain'}, false),
            Breadcrumb.create('Instructor Sessions', {name: 'instructorSessions'}, true)
        ]);
        next();
    }

    async mounted() {
        //Call store loaders (sessions called in asyncComputed)
        try {
            await this.$store.dispatch('instructor/loadInstructorSessionTemplate');
            await this.$store.dispatch('instructor/loadInstructors');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    collapse(session) {
        this.$root.$emit('bv::toggle::collapse', sprintf('instructor-session-%d', session.workshopInstanceId));
    }

    get newSessionModalId() {
        return 'new-session-modal';
    }

    get rosterModalId() {
        return sprintf('instructor-roster-modal-%d', this.currentSession.workshopInstanceId);
    }

    get registrationModalId() {
        return sprintf('instructor-register-modal-%d', this.currentSession.workshopInstanceId);
    }

    showNewSession() {
        this.newSession = this.newTrackTemplate;
        this.$bvModal.show(this.newSessionModalId);
    }

    showRoster(session) {
        this.currentSession = session;
        setTimeout(() => {
            this.$bvModal.show(this.rosterModalId);
        }, 500);
    }

    showRegistration(session) {
        this.currentSession = session;
        setTimeout(() => {
            this.$bvModal.show(this.registrationModalId);
        }, 500);
    }

    get newTrackTemplate() {
        const template = store.getters['instructor/instructorSessionTemplate'];
        return template;
    }

    //Can the logged in user add/edit/delete instructor sessions?
    get allowSessionChanges() {
        return true;  //Admin only module
    }

    //Can the logged in user create/cancel instructor session registrations?
    allowRegistrationChanges(session) {
        const currentUser = store.getters['userSession/getUser'];
        return session.allowRegistrationChanges(currentUser.securityLevel);
    }

    isEditable(session) {
        return !session.canceled;
    }

    isRegistrable(session) {
        return this.allowRegistrationChanges(session) && !session.canceled;
    }

    async edit(event, session) {
        this.currentSession = session;
        // console.log(this.currentSession);
        switch (event) {
            case 'cancelEdit':
                this.collapse(this.currentSession);
                this.currentSession.rollback();
                return;
            case 'deleteSession':
                const deleteCheck = await this.$bvModal.msgBoxConfirm(sprintf(
                    'Are you sure you wish to delete %s?',
                    this.currentSession.label), {
                    title: 'Confirm Delete',
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    headerBgVariant: 'dark',
                    headerTextVariant: 'white'
                });
                if (!deleteCheck) {
                    return;
                }
                try {
                    await this.$store.dispatch('instructor/deleteInstructorSession', this.currentSession.workshopInstanceId, {root: true});
                    this.collapse(this.currentSession);
                    this.refreshSessions();
                }
                catch (error) {
                    await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
                }
                return;
            case 'cancelSession':
                const cancelCheck = await this.$bvModal.msgBoxConfirm(sprintf(
                    'Are you sure you wish to cancel %s?',
                    this.currentSession.label), {
                    title: 'Confirm Cancel',
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    headerBgVariant: 'dark',
                    headerTextVariant: 'white',
                    okTitle: 'Yes',
                    cancelTitle: 'No'
                });
                if (!cancelCheck) {
                    return;
                }
                this.currentSession.cancel();
                try {
                    await this.$store.dispatch('instructor/processInstructorSession', this.currentSession, {root: true});
                    this.collapse(this.currentSession);
                    this.refreshSessions();
                }
                catch (error) {
                    await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
                }
                return;
            case 'saveSession':
                try {
                    await this.$store.dispatch('instructor/processInstructorSession', this.currentSession.workspace, {root: true});
                    this.collapse(this.currentSession);
                    this.currentSession.commit();  //Erases dirty flags, so call after saving
                    this.refreshSessions();
                }
                catch (error) {
                    await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
                }
                return;
            default:
                console.log('No edit action configured for event ', event);
                return;
        }
    }

    cancelNewSession() {
        this.$bvModal.hide('new-session-modal');
        this.newSession.rollback();
    }

    async saveNewSession() {
        try {
            await this.$store.dispatch('instructor/processInstructorSession', this.newSession.workspace, {root: true});
            this.$bvModal.hide('new-session-modal');
            this.newSession.rollback();
            this.refreshSessions();
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    prevYear() {
        this.selectedYear = subYears(this.selectedYear, 1);
    }

    nextYear() {
        this.selectedYear = addYears(this.selectedYear, 1);
    }
}
</script>

<style scoped>
    span.session-heading {
        font-weight: bold;
    }
</style>