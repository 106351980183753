<template>
    <b-card no-body class="border-0 p-0 m-0">
        <b-card-header>
            <strong>Instructors Registered for {{session.label}}</strong>
        </b-card-header>
        <b-row>
            <b-col>
                <b-form-group label-for="ubc-id-search" label="UBC ID:">
                    <b-input-group>
                        <b-input size="sm" placeholder="Filter by U-0000-0000" v-model="filters.ubcId"/>
                        <b-input-group-addon>
                            <b-button size="sm" @click="filters.ubcId = null">
                                <font-awesome-icon icon="times" />
                            </b-button>
                        </b-input-group-addon>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label-for="name-search" label="Name:">
                    <b-input-group>
                        <b-input id="name-search" size="sm" placeholder="Filter by Name" v-model="filters.name" />
                        <b-input-group-addon>
                            <b-button size="sm" @click="filters.name = null">
                                <font-awesome-icon icon="times" />
                            </b-button>
                        </b-input-group-addon>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div>
                    <b-table small striped hover outlined stacked="sm" head-variant="dark"
                             :items="tableData" :fields="fields">
                        <template v-slot:cell(ubcId)="row">{{row.item.user.ubcId}}</template>
                        <template v-slot:cell(fullname)="row">{{row.item.user.fullname}}</template>
                        <template v-slot:cell(register)="row">
                            <template v-if="allowRegistrationChanges">
                                <b-dropdown
                                    :variant="statusVariant(row.item.status)"
                                    :text=row.item.status
                                    size="sm">
                                    <b-dropdown-item
                                        href="#" class="options"
                                        @click="cancel(row.item)">Cancel registration</b-dropdown-item>
                                </b-dropdown>
                            </template>
                            <template v-else>
                                <b-button :variant="statusVariant(registeredStatus)" size="sm">
                                    {{registeredStatus}}
                                </b-button>
                            </template>
                        </template>
                    </b-table>
                    <template v-if="!instructors">
                        No registered instructors found for this session
                    </template>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
    import { Prop, Component, Vue} from 'vue-property-decorator';
    import {sprintf} from 'sprintf-js';
    import _ from 'underscore';
    import {InstructorSession, NULL_INSTRUCTOR_SESSION} from "@/model/session";
    import {Status} from "@/model/registration";
    import store from '@/store/store';
    import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

    @Component
    export default class InstructorRoster extends Vue {
        //For non-registrable sessions, roster should be read-only
        @Prop({type: Boolean, default: false}) allowRegistrationChanges;
        @Prop({type: InstructorSession, default: NULL_INSTRUCTOR_SESSION}) session;

        filters = {
            ubcId: null,
            name: null
        };

        registeredStatus = Status.REGISTERED;

        get instructors() {
            return store.getters['instructor/registeredInstructors'](this.session.workshopInstanceId) || [];
        }

        async cancel(registrationRequest) {
            //Check registration
            const registration = registrationRequest.registration;

            if (_.isUndefined(registration) || !!registration.canceled || !registration.cancelable) {
                return await this.$bvModal.msgBoxOk(
                    'Sorry, this instructor session registration is not eligible for cancellation.');
            }

            //Confirm cancellation
            const cancelCheck = await this.$bvModal.msgBoxConfirm(sprintf(
                'Are you sure you wish to cancel %s\'s registration for %s?',
                registrationRequest.user.fullname, registrationRequest.session.label), {
                size: 'sm',
                buttonSize: 'sm',
                title: 'Confirm Cancel',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                headerBgVariant: 'warning'
            });

            if (!cancelCheck) {
                return;
            }

            registration.canceled = true;

            try {

                await this.$store.dispatch('instructor/cancelRegistration', registrationRequest);

            } catch (error) {
                this.$bvModal.msgBoxOk(error.message, errorModalOptions);
                registration.canceled = false;
            }
        }

        statusVariant(status) {
            return Status.variant(status);
        }

        get fields() {
            return [{
                key: 'ubcId',
                label: 'UBC ID',
                sortable: true
            }, {
                key: 'fullname',
                label: 'Name',
                sortable: true
            }, {
                key: 'register',
                label: 'Status',
                sortable: false
            }];
        }

        get tableData() {
            return _.chain(this.instructors)
                    .filter(ins => {
                        const nf = (this.filters.name || '').trim();
                        const re = _.isEmpty(nf) ? null : new RegExp(nf, 'i');
                        return null === re || re.test(ins.user.fullname);
                    })
                    .filter(ins => {
                        const uid = (this.filters.ubcId || '').trim().replace(/\D/g, '');
                        const re = _.isEmpty(uid) ? null : new RegExp(uid, 'i');
                        return null === re || re.test(ins.user.ubcId);
                    })
                    .sortBy(ins => ins.user.fullname)
                    .value();
        }

        async refresh() {
            try {

                await store.dispatch('instructor/loadInstructors');

            } catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        }

        async mounted() {
            this.refresh();
        }
    }
</script>

<style scoped>
    b-dropdown-item.options {
    }
</style>